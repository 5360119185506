export class MultiselectClass {
  get #pseudoKey() {
    return '@key';
  }

  #inputObject;
  #inputValueParents;
  #inputValueKeys;

  selectAll() {
    return Object.keys(this.#inputValueKeys);
  }

  getRightColumnMap(sSearchText, aSelectValues) {
    if (sSearchText === undefined) sSearchText = '';
    let aResult = [];
    if (aSelectValues.length !== 0) {
      let rUseKeys = {
        grouping: [],
        items: []
      };
      aSelectValues.forEach((val) => {
        let bIncludeItem = false;
        if (!rUseKeys.grouping.includes(this.#inputValueParents[val])) {
          rUseKeys.grouping.push(this.#inputValueParents[val]);
          bIncludeItem = true;
        }
        if (bIncludeItem || !rUseKeys.items.includes(this.#inputValueKeys[val])) {
          rUseKeys.items.push(this.#inputValueKeys[val]);
        }
      });
      aResult = this.#commonBuildMap(sSearchText, [], rUseKeys);
    }
    return aResult;
  }

  getRightColumnMapOld(sSearchText, aSelectValues) {
    if (sSearchText === undefined) sSearchText = '';
    let aResult = [];
    if (aSelectValues.length !== 0) {
      let rInputObjectSelected = {};
      aSelectValues.forEach((val) => {
        const sGroupKey = this.#inputValueParents[val];
        if (sGroupKey !== undefined) {
          if (rInputObjectSelected[sGroupKey] === undefined) {
            rInputObjectSelected[sGroupKey] = {
              sGroupName: this.#inputObject[sGroupKey].sGroupName,
              searchStr: this.#inputObject[sGroupKey].searchStr,
              items: {}
            };
          }
          const rCurrentItem = this.#inputObject[sGroupKey].items[this.#inputValueKeys[val]];
          if (rCurrentItem !== undefined) rInputObjectSelected[sGroupKey].items[this.#inputValueKeys[val]] = {...rCurrentItem};
        }
      });
      aResult = this.#commonBuildMap(rInputObjectSelected, sSearchText, []);
    }
    return aResult;
  }

  // Возвращает дополенный массив aSelectValues выделенной группы sGroupKey
  selectLeftGrouping(sGroupKey, aSelectValues) {
    let aResult = [...aSelectValues];
    if (sGroupKey.length !== 0) {
      if (this.#inputObject[sGroupKey] !== undefined) {
        for (const [_, rItem] of Object.entries(this.#inputObject[sGroupKey].items)) {
          if (!aSelectValues.includes(rItem.value)) aResult.push(rItem.value);
        }
      }
    }
    return aResult;
  }

  // Возвращает очищенный массив aSelectValues без выделенной группы sGroupKey
  selectRightGrouping(sGroupKey, aSelectValues) {
    if (aSelectValues === undefined) aSelectValues = [];
    let aResult = [];
    if (this.#inputObject[sGroupKey] !== undefined) {
      aSelectValues.forEach((val) => {
        if (this.#inputValueParents[val] !== sGroupKey) aResult.push(val);
      });
    }
    return aResult;
  }

  getLeftColumnMap(sSearchText, aSelectValues) {
    return this.#commonBuildMap(sSearchText, aSelectValues);
  }

  #commonBuildMap(sSearchText, aSelectValues, rUseOnlyKeys) {
    if (sSearchText === undefined) sSearchText = '';
    sSearchText = sSearchText.trim().toUpperCase();
    let aShowGroupingKeys = [];
    let aResult = [];
    if (typeof sSearchText === 'string') {
      for (const [sFLKey, rFirstLevel] of Object.entries(this.#inputObject)) {
        if (rUseOnlyKeys === undefined || rUseOnlyKeys.grouping.includes(sFLKey)) {
          let iGroupSelectedIndex = undefined;
          if ((sSearchText.length !== 0 && rFirstLevel.searchStr.includes(sSearchText)) || sSearchText.length === 0) {
            iGroupSelectedIndex = this.#addGroupToMap(aResult, aShowGroupingKeys, sFLKey, rFirstLevel);
          }
          let aSelectChild = [];
          let aSelectedChild = [];
          for (const [sItemKey, rSecondLevel] of Object.entries(rFirstLevel.items)) {
            if (rUseOnlyKeys === undefined || rUseOnlyKeys.items.includes(sItemKey)) {
              if (!aSelectValues.includes(rSecondLevel.value)) {
                if ((sSearchText.length !== 0 && rSecondLevel.searchStr.includes(sSearchText)) || sSearchText.length === 0) {
                  if (iGroupSelectedIndex === undefined) {
                    iGroupSelectedIndex = this.#addGroupToMap(aResult, aShowGroupingKeys, sFLKey, rFirstLevel);
                  }
                  aSelectChild.push({
                    ...rSecondLevel,
                    key: sItemKey
                  });
                }
              } else {
                aSelectedChild.push(sItemKey);
              }
            }
          }
          if (iGroupSelectedIndex !== undefined) {
            if (aSelectChild.length === 0 && aSelectedChild.length === 0) {
              // Выбираем весь отдел
              for (const [sItemKey, rSecondLevel] of Object.entries(rFirstLevel.items)) {
                aSelectChild.push({
                  ...rSecondLevel,
                  key: sItemKey
                });
              }
            }
            if (aSelectChild.length !== 0) {
              aResult[iGroupSelectedIndex].items.push(...aSelectChild);
            }
            if (aSelectedChild.length !== 0 && aSelectChild.length === 0) {
              aResult.splice(iGroupSelectedIndex, 1);
              aShowGroupingKeys.splice(iGroupSelectedIndex, 1);
            }
          }
        }
      }
    }
    return aResult;
  }

  #addGroupToMap(aResult, aShowGroupingKeys, sFLKey, rFirstLevel) {
    const iMapIndex = aShowGroupingKeys.push(sFLKey) - 1;
    aResult[iMapIndex] = {
      sGroupName: rFirstLevel.sGroupName,
      key: sFLKey,
      items: []
    };
    return iMapIndex;
  }

  constructor(rInputObject, rFieldsRemap) {
    this.#inputObject = {};
    this.#inputValueParents = {};
    this.#inputValueKeys = {};

    for (const [sFLKey, rFirstLevel] of Object.entries(rInputObject)) {
      if (rFirstLevel instanceof Object) {
        const GroupKey = (rFieldsRemap.grouping.key === this.#pseudoKey) ? sFLKey : rFirstLevel[rFieldsRemap.grouping.key];
        const GroupTitle = (rFieldsRemap.grouping.sGroupName === this.#pseudoKey) ? sFLKey : rFirstLevel[rFieldsRemap.grouping.sGroupName];
        const aItemsList = rFirstLevel[rFieldsRemap.grouping.items];
        if (aItemsList !== undefined) {
          this.#inputObject[GroupKey] = {
            sGroupName: GroupTitle,
            searchStr: GroupTitle.toUpperCase(),
            items: {}
          };
          for (const [sItemKey, rSecondLevel] of Object.entries(aItemsList)) {
            const ItemKey = (rFieldsRemap.items.key === this.#pseudoKey) ? sItemKey : rSecondLevel[rFieldsRemap.items.key];
            const ItemTitle = (rFieldsRemap.items.title === this.#pseudoKey) ? sItemKey : rSecondLevel[rFieldsRemap.items.title];
            const ItemValue = (rFieldsRemap.items.value === this.#pseudoKey) ? sItemKey : rSecondLevel[rFieldsRemap.items.value];
            if (ItemValue !== undefined) {
              this.#inputObject[GroupKey].items[ItemKey] = {
                title: ItemTitle,
                value: ItemValue,
                searchStr: ItemTitle.toUpperCase()
              };
              this.#inputValueParents[ItemValue] = GroupKey;
              this.#inputValueKeys[ItemValue] = ItemKey;
            }
          }
        }
      }
    }
  }
}
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction } from 'react';

import { userStore } from '../../../store/userStore/UserStore';

import MenuLink from './MenuLink/MenuLink';

type MenuNavProps = {
  isHovered: boolean;
  setIsHovered: Dispatch<SetStateAction<boolean>>;
};

const MenuNav = observer(function MenuNav({
  isHovered,
  setIsHovered,
}: MenuNavProps) {
  const { pages } = userStore;
  const currentPages = pages?.hasErrors ? [] : Object.values(pages ?? {});

  return (
    <nav className="d-flex flex-column gap-3">
      {currentPages.length > 0 &&
        currentPages.map((config) => (
          <MenuLink
            {...{ config, isHovered, setIsHovered, key: config.sUid }}
          />
        ))}
    </nav>
  );
});

export default MenuNav;

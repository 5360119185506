import { makeAutoObservable } from 'mobx';
import {
  EGeneralTab,
  FilterInputsType,
  FilterRegionResponse,
  IFilterDataResponse,
  IFilterRequest,
} from '../../types/filter/filterTypes';
import {
  fetchFilterData,
  filterDataPresets,
} from '../../api/filter/fetchFilterData';
import { fetchRegionsData } from '../../api/filter/fetchRegionsData';
import { filterOptionsStore } from './FilterOptionsStore';
import { MultiselectClass } from '../../utils/filter/multiselect-class.mjs';
import {
  rStructureAreas,
  rStructureMap,
} from '../../utils/filter/filterFieldStructure';

class FilterStore {
  filterData: IFilterDataResponse | null = null;
  isFilterDataLoading: boolean = true;
  filterRegions: FilterRegionResponse | null = null;
  isFilterRegionsLoading: boolean = false;
  filterInputs: FilterInputsType = {};
  sortOrganizationsBy: number = 0;
  filterRequest: IFilterRequest | null = null;
  filterOptionsService: typeof filterOptionsStore;
  generalActiveTab: EGeneralTab = EGeneralTab.mainManager;
  generalTabCheckbox: boolean = true;
  managersForm: MultiselectClass | null = null;
  areasForm: MultiselectClass | null = null;

  constructor(filterOptionsService: typeof filterOptionsStore) {
    makeAutoObservable(this);
    this.filterOptionsService = filterOptionsService;
  }

  private setFilterData(data: IFilterDataResponse) {
    this.filterData = data;
    this.setManagersForm(data);
    this.setAreasForm(data);
  }

  private setFilterDataLoading(value: boolean) {
    this.isFilterDataLoading = value;
  }

  private setRegionsData(data: any) {
    this.filterRegions = data;
  }

  private setRegionsDataLoading(value: boolean) {
    this.isFilterRegionsLoading = value;
  }

  setFilterRequest(data: IFilterRequest | null) {
    this.filterRequest = data;
  }

  setSortValue(value: number) {
    this.sortOrganizationsBy = value;
  }

  updateFilterInputs(data: FilterInputsType) {
    this.filterInputs = data;
  }

  setManagersForm(data: IFilterDataResponse) {
    this.managersForm = new MultiselectClass(
      data?.aClientManagers || [],
      rStructureMap,
    );
  }

  setAreasForm(data: IFilterDataResponse) {
    this.areasForm = new MultiselectClass(
      data?.aOblList || [],
      rStructureAreas,
    );
  }

  setGeneralActiveTab(value: EGeneralTab) {
    this.generalActiveTab = value;
  }

  setGeneralTabCheckbox() {
    this.generalTabCheckbox = !this.generalTabCheckbox;
  }

  async fetchData() {
    const response = await fetchFilterData(filterDataPresets.FULL_FILTER);
    this.setFilterData(response);
    this.setManagersForm(response);
    this.filterOptionsService.setFilterOptions(response);
    this.filterOptionsService.setGroupOptions(response);
    this.filterOptionsService.setGroupOptionsWithTitle(response);
    this.filterOptionsService.setOpfOptions(response);
    this.filterOptionsService.setAddTypesOptions(response);
    this.setFilterDataLoading(false);
  }

  async fetchRegions(areas: number[]) {
    this.setRegionsDataLoading(true);
    const response = await fetchRegionsData(areas);
    this.setRegionsData(response?.aRegionsByObl || []);
    this.filterOptionsService.setDistrictOptions(response?.aRegionsByObl || []);
    this.setRegionsDataLoading(false);
  }
}

export const filterStore = new FilterStore(filterOptionsStore);

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { VIEW_MOBILE } from '../../constants/screen';
import { modalStore } from '../../store/modal/ModalStore';
import { screenStore } from '../../store/ScreenStore';

import AppModal from '../helpers/Modal/AppModal/AppModal';

const ModalList = observer(function ModalList() {
  const { modalList } = modalStore;
  const { screenType } = screenStore;

  useEffect(() => {
    window.document.body.style.overflow =
      modalList.length > 0 ? 'hidden' : 'auto';
    if (screenType === VIEW_MOBILE) {
      window.document.body.style.overflow =
        modalList.length > 0 ? 'hidden' : 'auto';
    }
    if (screenType !== VIEW_MOBILE) {
      window.document.body.style.marginRight =
        modalList.length > 0 ? '17px' : '0';
    }
  }, [modalList.length, screenType]);

  return (
    <>
      {modalList.length > 0 &&
        modalList.map((modal, index) => (
          <AppModal key={index} closeOverflow={modal.closeOverflow}>
            {modal.children}
          </AppModal>
        ))}
    </>
  );
});

export default ModalList;

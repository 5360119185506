import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { ROUTES } from '../routes/routes';
import { modalStore } from '../store/modal/ModalStore';
import { userStore } from '../store/userStore/UserStore';
import {
  CL_API_ERR_AUTH,
  CL_API_ERR_AUTH_BLOCK_IP,
  CL_API_ERR_NONAME,
  CL_API_ERR_SERVER,
  CL_API_ERR_SQL,
} from '../constants/errors/errors';

import { ModalError } from '../components/lazy';

export const useAppErrorController = () => {
  const handleBaseError = (e: any) => {
    modalStore.openModal({
      children: <ModalError title={e.detail.errMsg} />,
    });
  };

  const handleAuthError = function (e: any) {
    userStore.logoutUser({ totalLogout: false });
    if (e.type === CL_API_ERR_AUTH) {
      userStore.logoutUser({});
    }
    return <Navigate to={ROUTES.AUTH} replace />;
  };

  useEffect(() => {
    window.addEventListener(CL_API_ERR_AUTH, handleAuthError);
    window.addEventListener(CL_API_ERR_AUTH_BLOCK_IP, handleAuthError);
    window.addEventListener(CL_API_ERR_NONAME, handleBaseError);
    window.addEventListener(CL_API_ERR_SERVER, handleBaseError);
    window.addEventListener(CL_API_ERR_SQL, handleBaseError);

    return () => {
      window.removeEventListener(CL_API_ERR_AUTH, handleAuthError);
      window.removeEventListener(CL_API_ERR_AUTH_BLOCK_IP, handleAuthError);
      window.removeEventListener(CL_API_ERR_NONAME, handleBaseError);
      window.removeEventListener(CL_API_ERR_SERVER, handleBaseError);
      window.removeEventListener(CL_API_ERR_SQL, handleBaseError);
    };
  }, []);
};

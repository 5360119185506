import React, { lazy, Suspense } from 'react';
import { Spinner } from 'react-bootstrap';

const MenuMobileCloseButtonLazy = lazy(
  () =>
    import('../ui/buttons/menu/MenuMobileCloseButton/MenuMobileCloseButton'),
);
const CategoryManagersTabLazy = lazy(
  () =>
    import(
      '../organization/FilterOrganization/filterTabs/GeneralFilterTab/ManagersTabs/CategoryManagersTab'
    ),
);
const FinanceChangeManagerModuleLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/FInanceChangeManager/FinanceChangeManagerModule'
    ),
);
const TechnicInfoDesktopLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/InformationTab/TechnicInfoBlock/TechnicInfoDesktop'
    ),
);
const TechnicInfoMobileLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/InformationTab/TechnicInfoBlock/TechnicInfoMobile/TechnicInfoMobile'
    ),
);
const InformationTabGlobalMarksLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/InformationTab/InformationTabGlobalMarks'
    ),
);
const InformationTabSync1CLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/InformationTab/InformationTabSync1C'
    ),
);
const InformationTabDeleteMarkLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/InformationTab/InformationTabDeleteMark'
    ),
);
const OrganizationBlockFullLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardContent/cardContent/OrganizationBlockFull'
    ),
);
const OrganizationBlockWarningLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardContent/cardContent/OrganizationBlockWarning'
    ),
);
const HeaderSearchLazy = lazy(
  () => import('../layouts/Header/HeaderSearch/HeaderSearch'),
);
const CardNavDesktopLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/CardNavDesktop'
    ),
);
const CardNavMobileLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/CardNavMobile'
    ),
);
const FinanceTabLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTab'
    ),
);
const ContactsTabLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/ContactsTab/ContactsTab'
    ),
);
const HoldingTabLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/HoldingTab/HoldingTab'
    ),
);
const InformationTabLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/InformationTab/InformationTab'
    ),
);
const FinanceBillModalMobileLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/FinanceBillModal/FinanceBillModalMobile'
    ),
);
const FinanceBillModalDesktopLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/FinanceBillModal/FinanceBillModalDesktop'
    ),
);
const FinanceSalesListMobileLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/FinanceSalesList/FinanceSalesListMobile'
    ),
);
const FinanceSalesListDesktopLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/FinanceSalesList/FinanceSalesListDesktop'
    ),
);
const AuthLazy = lazy(() => import('../../pages/System/Auth/Auth'));
const NotFoundLazy = lazy(() => import('../../pages/System/NotFound/NotFound'));
const ModalErrorLazy = lazy(
  () => import('../helpers/Modal/ModalError/ModalError'),
);
const FinanceCalendarMobileLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/SalesCalendar/FinanceCalendarMobile'
    ),
);
const FinanceCalendarDesktopLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/SalesCalendar/FinanceCalendarDesktop'
    ),
);
const GantTableCorrModalLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/GantTableCorrModal/GantTableCorrModal'
    ),
);
const FinanceDoc1CMobileLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/Doc1C/FinanceDoc1CMobile'
    ),
);
const FinanceDoc1CDesktopLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/Doc1C/FinanceDoc1CDesktop'
    ),
);
const GantTableDesktopLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/FinanceGantTable/GantTableDesktop'
    ),
);
const GantTableMobileLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/FinanceGantTable/GantTableMobile'
    ),
);
const TasksLazy = lazy(() => import('../../pages/Tasks/Tasks'));
const OrganizationsPageLazy = lazy(
  () => import('../../pages/OrganizationsPage/OrganizationsPage'),
);
const MainLazy = lazy(() => import('../../pages/Main/Main'));
const AdditionalFilterTabLazy = lazy(
  () =>
    import(
      '../organization/FilterOrganization/filterTabs/AdditionalFilterTab/AdditionalFilterTab'
    ),
);
const SalesFilterTabLazy = lazy(
  () =>
    import(
      '../organization/FilterOrganization/filterTabs/SalesFilterTab/SalesFilterTab'
    ),
);
const ModalQrCodeLazy = lazy(
  () => import('../helpers/Modal/ModalQrCode/ModalQrCode'),
);
const AppBreadCrumbsLazy = lazy(
  () => import('../helpers/Breadcrumbs/AppBreadCrumbs'),
);
const AppPlayerLazy = lazy(() => import('../helpers/AppPlayer/AppPlayer'));
const ChangeContactsInfoLazy = lazy(
  () =>
    import(
      '../organization/changeOrganization/ChangeContactsInfo/ChangeContactsInfo'
    ),
);
const ChangeAddressInfoLazy = lazy(
  () =>
    import(
      '../organization/changeOrganization/ChangeAddressInfo/ChangeAddressInfo'
    ),
);
const FinancePaymentsModalLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/FinancePaymentsModal/FinancePaymentsModal'
    ),
);
const FinanceSalesModalLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/FinanceSalesModal/FinanceSalesModal'
    ),
);
const ModalCurrentOrganizationLazy = lazy(
  () =>
    import(
      '../helpers/Modal/ModalCurrentOrganization/ModalCurrentOrganization'
    ),
);
const ChangeGeneralInfoLazy = lazy(
  () =>
    import(
      '../organization/changeOrganization/ChangeGeneralInfo/ChangeGeneralInfo'
    ),
);
const ModalChangeManagerLazy = lazy(
  () => import('../helpers/Modal/ModalChangeManager/ModalChangeManager'),
);
const FinanceBillModalLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/FinanceBillModal/FinanceBillModal'
    ),
);
const ChangeTechnicInfoLazy = lazy(
  () =>
    import(
      '../organization/changeOrganization/ChangeTechnicInfo/ChangeTechnicInfo'
    ),
);
const DesktopContactsLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/ContactsTab/DesktopContacts'
    ),
);
const MobileContactsLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/ContactsTab/MobileContacts'
    ),
);
const ChangeDataInfoLazy = lazy(
  () =>
    import('../organization/changeOrganization/ChangeDataInfo/ChangeDataInfo'),
);
const GantTableLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/FinanceGantTable/GantTable'
    ),
);
const FinanceSalesCalendarLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/SalesCalendar/FinanceSalesCalendar'
    ),
);
const FinanceDoc1CLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/Doc1C/FinanceDoc1C'
    ),
);
const FinanceSalesListLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/FinanceSalesList/FinanceSalesList'
    ),
);
const FinanceTabMobileNavLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabMobileNav'
    ),
);
const FinanceTabDesktopNavLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabDesktopNav'
    ),
);
const FinanceSalesModalDesktopLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/FinanceSalesModal/FinanceSalesModalDesktop'
    ),
);
const FinanceSalesModalMobileLazy = lazy(
  () =>
    import(
      '../organization/OrganizationCard/OrganizationCardTabs/FinanceTab/FinanceTabs/financeModals/FinanceSalesModal/FinanceSalesModalMobile'
    ),
);
const OrganizationCardLazy = lazy(
  () => import('../organization/OrganizationCard/OrganizationCard'),
);
const AddNewOrganizationLazy = lazy(
  () => import('../organization/AddNewOrganization/AddNewOrganization'),
);
const FilterOrganizationLazy = lazy(
  () => import('../organization/FilterOrganization/FilterOrganization'),
);
const GeneralFilterTabLazy = lazy(
  () =>
    import(
      '../organization/FilterOrganization/filterTabs/GeneralFilterTab/GeneralFilterTab'
    ),
);

const createLazyComponent = <T extends React.ComponentType<any>>(
  LazyComponent: React.LazyExoticComponent<T>,
  fallback?: boolean,
) => {
  return (props: React.ComponentProps<T>, componentProps?: any) => {
    return (
      <Suspense
        fallback={
          fallback ? (
            <div className="w-100 vh-100 d-flex align-items-center justify-content-center z-2">
              <Spinner variant="light" animation="border" />
            </div>
          ) : null
        }
      >
        <LazyComponent {...props} {...componentProps} />
      </Suspense>
    );
  };
};

export const delayImport = (promise: any) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 2000);
  }).then(() => promise);
};

export const MenuMobileCloseButton = createLazyComponent(
  MenuMobileCloseButtonLazy,
);
export const ModalQrCode = createLazyComponent(ModalQrCodeLazy, true);
export const AppBreadCrumbs = createLazyComponent(AppBreadCrumbsLazy);
export const AppPlayer = createLazyComponent(AppPlayerLazy);
export const ChangeContactsInfo = createLazyComponent(
  ChangeContactsInfoLazy,
  true,
);
export const FinancePaymentsModal = createLazyComponent(
  FinancePaymentsModalLazy,
  true,
);
export const ChangeAddressInfo = createLazyComponent(
  ChangeAddressInfoLazy,
  true,
);
export const FinanceSalesModal = createLazyComponent(
  FinanceSalesModalLazy,
  true,
);
export const ModalCurrentOrganization = createLazyComponent(
  ModalCurrentOrganizationLazy,
);
export const ChangeGeneralInfo = createLazyComponent(
  ChangeGeneralInfoLazy,
  true,
);
export const ModalChangeManager = createLazyComponent(
  ModalChangeManagerLazy,
  true,
);
export const FinanceBillModal = createLazyComponent(FinanceBillModalLazy, true);
export const ChangeTechnicInfo = createLazyComponent(
  ChangeTechnicInfoLazy,
  true,
);
export const DesktopContacts = createLazyComponent(DesktopContactsLazy);
export const MobileContacts = createLazyComponent(MobileContactsLazy);
export const ChangeDataInfo = createLazyComponent(ChangeDataInfoLazy, true);
export const GantTable = createLazyComponent(GantTableLazy);
export const FinanceSalesCalendar = createLazyComponent(
  FinanceSalesCalendarLazy,
);
export const FinanceDoc1C = createLazyComponent(FinanceDoc1CLazy);
export const FinanceSalesList = createLazyComponent(FinanceSalesListLazy);
export const FinanceTabMobileNav = createLazyComponent(FinanceTabMobileNavLazy);
export const FinanceTabDesktopNav = createLazyComponent(
  FinanceTabDesktopNavLazy,
);
export const FinanceSalesModalDesktop = createLazyComponent(
  FinanceSalesModalDesktopLazy,
);
export const FinanceSalesModalMobile = createLazyComponent(
  FinanceSalesModalMobileLazy,
);
export const OrganizationCard = createLazyComponent(OrganizationCardLazy, true);
export const AddNewOrganization = createLazyComponent(
  AddNewOrganizationLazy,
  true,
);
export const FilterOrganization = createLazyComponent(
  FilterOrganizationLazy,
  true,
);
export const GeneralFilterTab = createLazyComponent(GeneralFilterTabLazy, true);
export const SalesFilterTab = createLazyComponent(SalesFilterTabLazy, true);
export const AdditionalFilterTab = createLazyComponent(
  AdditionalFilterTabLazy,
  true,
);
export const Main = createLazyComponent(MainLazy);
export const OrganizationsPage = createLazyComponent(OrganizationsPageLazy);
export const Tasks = createLazyComponent(TasksLazy);
export const GantTableMobile = createLazyComponent(GantTableMobileLazy);
export const GantTableDesktop = createLazyComponent(GantTableDesktopLazy);
export const FinanceDoc1CDesktop = createLazyComponent(FinanceDoc1CDesktopLazy);
export const FinanceDoc1CMobile = createLazyComponent(FinanceDoc1CMobileLazy);
export const GantTableCorrModal = createLazyComponent(
  GantTableCorrModalLazy,
  true,
);
export const FinanceCalendarDesktop = createLazyComponent(
  FinanceCalendarDesktopLazy,
);
export const FinanceCalendarMobile = createLazyComponent(
  FinanceCalendarMobileLazy,
);
export const ModalError = createLazyComponent(ModalErrorLazy, true);
export const Auth = createLazyComponent(AuthLazy);
export const NotFound = createLazyComponent(NotFoundLazy);
export const FinanceSalesListDesktop = createLazyComponent(
  FinanceSalesListDesktopLazy,
);
export const FinanceSalesListMobile = createLazyComponent(
  FinanceSalesListMobileLazy,
);
export const FinanceBillModalDesktop = createLazyComponent(
  FinanceBillModalDesktopLazy,
);
export const FinanceBillModalMobile = createLazyComponent(
  FinanceBillModalMobileLazy,
);
export const InformationTab = createLazyComponent(InformationTabLazy, true);
export const HoldingTab = createLazyComponent(HoldingTabLazy, true);
export const ContactsTab = createLazyComponent(ContactsTabLazy, true);
export const FinanceTab = createLazyComponent(FinanceTabLazy, true);
export const CardNavMobile = createLazyComponent(CardNavMobileLazy);
export const CardNavDesktop = createLazyComponent(CardNavDesktopLazy);
export const HeaderSearch = createLazyComponent(HeaderSearchLazy);
export const OrganizationBlockWarning = createLazyComponent(
  OrganizationBlockWarningLazy,
);
export const OrganizationBlockFull = createLazyComponent(
  OrganizationBlockFullLazy,
);
export const InformationTabDeleteMark = createLazyComponent(
  InformationTabDeleteMarkLazy,
);
export const InformationTabSync1C = createLazyComponent(
  InformationTabSync1CLazy,
);
export const InformationTabGlobalMarks = createLazyComponent(
  InformationTabGlobalMarksLazy,
);
export const TechnicInfoMobile = createLazyComponent(TechnicInfoMobileLazy);
export const TechnicInfoDesktop = createLazyComponent(TechnicInfoDesktopLazy);
export const FinanceChangeManagerModule = createLazyComponent(
  FinanceChangeManagerModuleLazy,
);
export const CategoryManagersTab = createLazyComponent(
  CategoryManagersTabLazy,
  true,
);

const Index = () => {
  return <div></div>;
};

export default Index;
